import { setUser } from '@sentry/remix';
import {
  AuthTokens,
  fetchAuthSession,
  fetchUserAttributes,
  FetchUserAttributesOutput,
  signOut,
} from 'aws-amplify/auth';
import { traceError } from '@/utils/trace-error';
import { pushService } from '@/services/push-service.ts';
import { identifyUser } from 'aws-amplify/analytics';

type SessionData = {
  tokens?: AuthTokens;
  userAttributes?: FetchUserAttributesOutput;
};

let sessionDataPromise: Promise<SessionData> | undefined;

let cachedUserAttributes: FetchUserAttributesOutput | undefined;

// super primitive cache invalidation
export const invalidateSession = () => {
  sessionDataPromise = undefined;
  cachedUserAttributes = undefined;

  setUser(null);
};

const fetchSession = async () => {
  const tokens = (await fetchAuthSession()).tokens;

  if (tokens && !cachedUserAttributes) {
    cachedUserAttributes = await fetchUserAttributes();
    if (cachedUserAttributes.sub) {
      identifyUser({
        userId: cachedUserAttributes.sub,
        userProfile: {
          email: cachedUserAttributes.email,
          name: cachedUserAttributes.preferred_username,
        },
      });
      setUser({
        id: cachedUserAttributes.sub,
        email: cachedUserAttributes.email,
        username: cachedUserAttributes.preferred_username,
      });
    }
  }

  return { tokens, userAttributes: cachedUserAttributes };
};

export const getAuthorizedData = () =>
  (sessionDataPromise ??= fetchSession())
    .catch(async (err) => {
      if (err.name === 'NetworkError') {
        throw err;
      }

      traceError(new Error('Failed to fetch session data', { cause: err }));

      try {
        const subscription = await pushService.getSubscription();
        if (subscription) await subscription.unsubscribe();
      } catch (err) {
        traceError(
          new Error(
            'Failed to unsubscribe from push notifications on token expire',
            { cause: err },
          ),
        );
      }

      await signOut();

      return {
        userAttributes: undefined,
        tokens: undefined,
      } satisfies SessionData;
    })
    .finally(() => {
      sessionDataPromise = undefined;
    });
